@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

body {
  background: white;
  height: 100%;
}

.playArea {
  background-image: url("../src/assets/images/bg.png");
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.8);
  border: 2px solid #e8b60236;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: calc(100%-424px);
    background-size: cover;
  }
}

.custom_bg {
  background-image: url("./assets/images/bg_2.png");
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.4);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-Coin {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/gif/coins-down.gif");
}

.textShadow {
  color: #ffcc00;
  text-shadow: 1px 1px #d4a45f, 2px 2px #d4a45f, 3px 3px #6a4511, 4px 6px black;
}

.highScore {
  color: white;
  text-shadow: 1px 1px white, 3px 2px black;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.jump {
  animation: jumpAnimation 0.4s;
}

@keyframes jumpAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
    color: #bf0101;
  }
  100% {
    transform: translateY(0);
    color: #ffff;
  }
}

.modal-background-transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/gif/transition.gif");
  background-position: center;
  z-index: 999;
}

::-webkit-scrollbar {
  width: 12px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; 
  appearance: none;
  background-color: #cb4f02;
  height: 2rem;
  width: 1rem;    
}
